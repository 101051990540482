import { Component, OnInit, Input, ViewChild, TemplateRef, Output, EventEmitter } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { UserService } from '../services/User.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-switch-market',
  templateUrl: './switch-market.component.html',
  styleUrls: ['./switch-market.component.scss']
})
export class SwitchMarketComponent implements OnInit {
  modalRef: BsModalRef;
  switchData;
  @Input() lastAccessed;
  tempNav;
  @Input() marketList;
  @Output() hideSwitchMarketPopup: EventEmitter<any> = new EventEmitter<any>();
  private subscriptions: Subscription[] = [];
  @ViewChild('template1', { static: true }) switchmarketpopup: TemplateRef<any>;
  @ViewChild('template', { static: true }) alertpopup: TemplateRef<any>;
  defaultStoreName: any;
  public fields: Object = { text: 'text', value: 'value' };
  constructor(private modalService: BsModalService,
    private userService:UserService,
              private router: Router) { }

  ngOnInit() {
    this.tempNav = this.router;  
  }

  reloadCurrentRoute(): void {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      // navigate back to the original route
      this.router.navigate([currentUrl]);
    });
  }


  updateChangedMarket() {
    this.subscriptions.push(this.userService.updateSwitchedMarketId(this.lastAccessed).subscribe((response) => {
      if (response) {
        this.reloadCurrentRoute();
      }
    }));
    this.modalRef.hide();
    this.modalRef = null;
  }

  openModal(template: TemplateRef<any>) {
    var temp = this.marketList;
    this.marketList =[];
    this.marketList = temp.map((el, i) => {
      return {
        value: el.MarketId,
        text: el.MarketName
      }
    });
    this.modalRef = this.modalService.show(template, { class: "modal-dialog-centered", backdrop: 'static', keyboard: false });
  }

  openSwitchmarketModal() {
    if (!this.modalRef) {
      this.openModal(this.switchmarketpopup);
    }
  }

  openemptystoreModel() {
    if (!this.modalRef) {
      this.openModal(this.alertpopup);
    }
  }

  cancelSwitchMarket() {
    this.modalRef.hide();
    this.modalRef = null;
    this.hideSwitchMarketPopup.emit();
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
   providedIn: 'root',
})

export class UserService {
   private baseURL;
   bearerToken;

    constructor(private http: HttpClient,
        private authService:AuthService) {
         this.baseURL = environment.apiBaseUrl;
    }

   getToken(userData): Observable<any> {
      const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
      const url = `${this.baseURL}/user`;
      return this.http.post<any>(url, userData, options)
         .pipe(
            map(res => {
               if (res.Token) {
                  this.authService.isLoggedIn = true;
                  localStorage.setItem('token', res.Token);
                  return res;
               }
               else {
                  this.authService.isLoggedIn = false;
               }
            }),
            catchError(this.handleError)
         );
   }

   updateSwitchedMarketId(marketId): Observable<any> {
       const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
      const url = `${this.baseURL}/user/UpdateDefaultMarketId?switchedMarketId=${marketId}`;
      return this.http.post<any>(url, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
     }

   clearToken(): void {
      localStorage.removeItem('token');
      this.authService.isLoggedIn = false;
    }

    ResetPassword(userData): Observable<any> {
      const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
      const url = `${this.baseURL}/User/ResetPassword`;
      return this.http.post<any>(url, userData, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
   }

   GetCurrentUser(): Observable<any> {
      const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
      const url = `${this.baseURL}/user/GetCurrentUser`;
      return this.http.post<any>(url, options)
         .pipe(
            map(res => {
               const user = res as any;
               return user;
            }),
            catchError(this.handleError)
         );
   }

   private handleError(error: HttpErrorResponse) {
      if (error.error instanceof Error) {
         const errMessage = error.error.message;
         return throwError(errMessage);
      }
      return throwError(error || 'Node.js server error');
   }
}

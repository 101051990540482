import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BrowserDetectService {
  private userAgent: string = navigator.userAgent;

  isFirefox(): boolean {
    return /firefox|fxios/i.test(this.userAgent);
  }
}

import { Injectable } from '@angular/core';

@Injectable({
   providedIn: 'root',
})

export class AuthService {
    public isLoggedIn;

    isLoginAutenticated(){
        const token=localStorage.getItem('token');
        if (token) {
            return true;
        }
        else{
            return false;
        }
    }
}
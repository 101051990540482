import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { VendorPaymentsComponent } from './payments/vendor-payments.component';
const routes: Routes = [ 
  {
    path: 'admin',
    loadChildren: () => import('../admin/admin.module').then(m => m.AdmModule),
  },
  {
    path: 'ops/vendors/payments/:vid',
    component:VendorPaymentsComponent,
  }
];

@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ]
})

export class SharedRoutingModule {
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-success-tick',
  templateUrl: './success-tick.component.html',
  styleUrls: ['./success-tick.component.scss']
})
export class SuccessTickComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

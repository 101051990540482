import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import {  ICompanyCreditCardTypeModel, ICompanyCreditCardTypeList, ICreditCardTypeActiveMarketinfo, ICreditCardType } from 'src/app/Shared/models/CreditCardTypeModel';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})

export class CreditCardService {
    private baseURL: string; 
     constructor(private http: HttpClient) {
          this.baseURL = environment.apiBaseUrl;
    }
 
  private options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

   GetAllCompanyCreditCards(): Observable<any> {
    return this.http.get<any>(
        `${this.baseURL}/CreditCardType/GetAllCompanyCreditCardTypes`,
        { observe: 'response' })
        .pipe(
          map(res => {
           
            const result = res.body as any;
            return result;
          }),
          catchError(this.handleError)
        );
   }
    

   
   GetCreditCardActiveMarkets(Id: number): Observable<any> {
    
    return this.http.get<any>(
        `${this.baseURL}/CreditCardType/GetAllActiveMarketforCreditCardType?Id=${Id}`,
        { observe: 'response' })
        .pipe(
          map(res => {
            const result = res.body as any;
            return result;
          }),
          catchError(this.handleError)
        );
   }

      
   GetAllCreditCardsByMarket(Id: number): Observable<any> {
    
    return this.http.get<any>(
        `${this.baseURL}/CreditCardType/GetCreditCardTypesByMarket?Id=${Id}`,
        { observe: 'response' })
        .pipe(
          map(res => {
            const result = res.body as any;
            return result;
          }),
          catchError(this.handleError)
        );
   }


   
   SaveCompanyCreditCard(CompanyCreditCard: ICompanyCreditCardTypeModel): Observable<any> {
   const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        const url = `${this.baseURL}/CreditCardType/SaveCompanyCreditCardType`;
        return this.http.post<any>(url, CompanyCreditCard, options)
          .pipe(
            map(res => {
              const response = res as any[];
              return response;
            }),
            catchError(this.handleError)
          );
   }

   GetCompanyCreditCardById(Id: number): Observable<any> {
    return this.http.get<any>(
        `${this.baseURL}/CreditCardType/GetCompanyCreditCardTypeById?Id=${Id}`,
        
        { observe: 'response' })
        .pipe(
          map(res => {
            const result = res as any;
            return result;
          }),
          catchError(this.handleError)
        );
   }


 
   UpdateCompanyCreditCard(CompanyCreditCard: ICompanyCreditCardTypeModel): Observable<any> {
    return this.http.post<any>(
        `${this.baseURL}/CreditCardType/UpdateCompanyCreditCardType`,
        CompanyCreditCard,
        { observe: 'response' })
        .pipe(
          map(res => {
            const result = res as any;
            return result;
          }),
          catchError(this.handleError)
        );
   }

   DeleteCompanyCreditCardById(Id: number): Observable<any> {
    return this.http.get<any>(
        `${this.baseURL}/CreditCardType/DeleteCompanyCreditCardType?Id=${Id}`,
        
        { observe: 'response' })
        .pipe(
          map(res => {
            const result = res as any;
            return result;
          }),
          catchError(this.handleError)
        );
   }

      
   UpdateOrSaveCreditCard(CreditCard: ICreditCardTypeActiveMarketinfo): Observable<any> {
    
         const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
         const url = `${this.baseURL}/CreditCardType/UpdateOrSaveCreditCardType`;
         return this.http.post<any>(url, CreditCard, options)
           .pipe(
             map(res => {
               const response = res as any[];
               return response;
             }),
             catchError(this.handleError)
           );
    }

    UpdateCreditCard(CreditCard): Observable<any> {
      
           const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
           const url = `${this.baseURL}/CreditCardType/UpdateCreditCardType`;
           return this.http.post<any>(url, CreditCard, options)
             .pipe(
               map(res => {
                 const response = res as any[];
                 return response;
               }),
               catchError(this.handleError)
             );
      }
  

   private handleError(error: HttpErrorResponse) {
    console.error('server error:', error);
    if (error.error instanceof Error) {
      const errMessage = error.error.message;
     // this.appinsights.logEvent(errMessage);
      return throwError(errMessage);
    }
    return throwError(error || 'Node.js server error');
  }

}

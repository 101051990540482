import {Component, Input, OnInit,} from '@angular/core';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {
  @Input() IsMobilRental:boolean;

  ngOnInit(): void {
  }

  addClass(Cls) {
    (document.querySelector('#search_inner') as HTMLElement).classList.add(Cls);
  }
}

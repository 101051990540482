
<ng-template #template1>
    <div class="modal-content"> 
        <div class="modal-header">
            <h5 class="modal-title">Switch Market</h5>
        </div>
        <div class="modal-body">
            <div class="mx-0">
                <p>Markets : </p>

                <ejs-dropdownlist id='markets' [dataSource]='marketList' [fields]='fields' (filtering)='onFiltering($event)' [filterBarPlaceholder]="filterPlaceholder"
                [popupHeight]='height' [allowFiltering]='true' [placeholder]='watermark'
                [(ngModel)]="lastAccessed"></ejs-dropdownlist>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline-primary mr-3" (click)="cancelSwitchMarket()" i18n>Cancel</button>
            <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="updateChangedMarket()">Select</button>
        </div>
    </div>
</ng-template>


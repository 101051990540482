import { Pipe, PipeTransform } from "@angular/core";
import { CurrencyPipe } from '@angular/common';


@Pipe({
  name: 'customCurrency'
})

export class customCurrencyPipe implements PipeTransform {

  constructor(private currencyPipe: CurrencyPipe) { }

  transform(value: string): string {
    if (parseFloat(value) < 0) {
      return `(${this.currencyPipe.transform(value.toString().replace('-',''))})`;
    }
    return this.currencyPipe.transform(value);
  }

  customCurrencyInput(event): boolean {
    const inputValue = event.target.value;
    const charCode = (event.which) ? event.which : event.keyCode;
    const currentCursorPosition = event.target.selectionStart;
    if (charCode == 46 && inputValue.includes('.') && inputValue.indexOf('.') !== currentCursorPosition) {
      return false;
    }
    if (charCode > 31 && (charCode < 46 || charCode > 57 || charCode === 47)) {
      return false;
    }
    return true;
  }
}
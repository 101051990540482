import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-failure-cross',
  templateUrl: './failure-cross.component.html',
  styleUrls: ['./failure-cross.component.scss']
})
export class FailureCrossComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

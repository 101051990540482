import {ApplicationRef, Inject, Injectable} from '@angular/core';
import {AppConfig} from '../Shared/app-config.inject';
import {IAppConfig} from '../interfaces/app-config.interface';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  constructor(@Inject(AppConfig) private readonly appConfig: IAppConfig) {
  }

  get config(): IAppConfig {
    return this.appConfig;
  }
}

import { Component, OnDestroy, OnInit, Renderer2, } from '@angular/core';
import { BaseComponent } from './Shared/base.component';
import { BrowserDetectService } from './services/browser-detect.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})


export class AppComponent extends BaseComponent implements OnInit, OnDestroy {
   ngOnDestroy(): void {

  }
  constructor(private browserDetectService: BrowserDetectService, private renderer: Renderer2) {
    super();
  }

  ngOnInit() {
    if (this.browserDetectService.isFirefox()) {
      this.renderer.addClass(document.body, 'is-firefox');
    }
  }
}

import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DecimalPipe } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TopBarComponent } from '../top-bar/top-bar.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { GridAllModule } from '@syncfusion/ej2-angular-grids';
import { ToolbarModule } from '@syncfusion/ej2-angular-navigations';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { ToastModule } from '@syncfusion/ej2-angular-notifications';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { MaskedTextBoxModule, NumericTextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { TooltipAllModule } from '@syncfusion/ej2-angular-popups';
import { ToastrModule } from 'ngx-toastr';
import { SharedRoutingModule } from './shared-routing.module';
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
import { SearchBarComponent } from '../top-bar/search-bar/search-bar.component';
import { SelectTemplateComponent } from './select-template/select-template.component';
import { LoaderComponent } from './loader/loader.component';
import { customCurrencyPipe } from './customCurrency.pipe';
import { VendorSearchComponentComponent } from './vendor-search/vendor-search.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PhoneFormatPipe } from './phone-format.pipe';
import { VendorPaymentsComponent } from './payments/vendor-payments.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PaymentComponent } from './payments/payment/payment.component';
import { SuccessTickComponent } from './success-tick/success-tick.component';
import { FailureCrossComponent } from './failure-cross/failure-cross.component';
import { BoothLookUpComponent } from './booth-look-up/booth-look-up.component';
import { ComboBoxModule } from '@syncfusion/ej2-angular-dropdowns';
import { SwitchMarketComponent } from '../switch-market/switch-market.component';
import { HourglassSpinnerComponent } from './loader/hourglass-spinner/hourglass-spinner.component';
import { ZipCodePipe } from './zip-code.pipe';
import { AutopayComponent } from './autopay/autopay.component';

@NgModule({
    declarations: [TopBarComponent, SearchBarComponent, SelectTemplateComponent, LoaderComponent,
        customCurrencyPipe,VendorSearchComponentComponent, PhoneFormatPipe,VendorPaymentsComponent, PaymentComponent,SuccessTickComponent,FailureCrossComponent, BoothLookUpComponent,
        SwitchMarketComponent,
        HourglassSpinnerComponent,
        ZipCodePipe,
        AutopayComponent
    ],

    imports: [CommonModule, ModalModule.forRoot(), MaskedTextBoxModule, DragDropModule,
        GridAllModule, ToolbarModule, SharedRoutingModule, RichTextEditorAllModule,
        DropDownListModule, ToastModule, DatePickerModule,ToastrModule.forRoot({ preventDuplicates: true }), TooltipAllModule,
        NumericTextBoxModule,InfiniteScrollModule, FormsModule, ReactiveFormsModule,ComboBoxModule ],

    exports: [TopBarComponent, SearchBarComponent, LoaderComponent,HourglassSpinnerComponent,
        ToastModule, DropDownListModule, DatePickerModule, ToastrModule,SuccessTickComponent,FailureCrossComponent,SwitchMarketComponent,
        TooltipAllModule, SelectTemplateComponent, LoaderComponent,VendorSearchComponentComponent,customCurrencyPipe,PhoneFormatPipe,VendorPaymentsComponent,BoothLookUpComponent,ComboBoxModule,PaymentComponent,
        ZipCodePipe,AutopayComponent],
    providers: [CurrencyPipe, DecimalPipe,customCurrencyPipe],
})
export class SharedModule { }
